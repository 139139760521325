import store from "@/store";

export const setColor = function(res :any) {
    const COLORS = store.getters.getColors;
    res.data.forEach((ele: any, index: any) => {
        ele.color = "#F2F6FB";
        ele.groups.forEach((e:any) => {
            e.color = COLORS[index % COLORS.length];
        });
    });
}
