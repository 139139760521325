<template>
  <div>
    <Sidebar class="mini-sidebar" style="box-shadow: rgb(0 0 0 / 15%) 2.4px 2.4px 3.2px;    z-index: 100;" ></Sidebar>
    <Header id="header"></Header>
    <div id="header-title" style="position: relative; font-family: 'Noto Sans KR', sans-serif;   width: calc(100% - 285px); left: 256px; min-width: 750px; top: 85px; margin: 0px 15px; padding: 0px 15px; font-size: 40px; border-left: 5px solid #303d54;color: #303d54" >
      {{ currentPageName }}
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/layout/header/Header";
import Sidebar from "@/components/layout/Sidebar";
import axios from "axios";
import store from "@/store";
import { getParams } from "@/func/urlParams";
import Vue from "vue";
import router from "../router";
export default {
  name: "Layout",
  components: {
    Sidebar,
    Header
  },
  data() {
    return {
      pageName: {},
      currentPageName: null
    };
  },
  mounted() {
    const sideMenu = this.$store.getters.getSidebarMenu;
    for (const [section, menuArray] of Object.entries(sideMenu)) {
      for (const [index, menuInfo] of Object.entries(menuArray)) {
        this.pageName[menuInfo.enTitle] = menuInfo.title;
      }
    }
    this.currentPageName = this.pageName[this.$route.matched[1].name];
  },
  updated() {
    this.currentPageName = this.pageName[this.$route.matched[1].name];
  },
  async beforeRouteEnter(to, from, next) {
    // 데이터 셋 불러오기
    const requestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/getDataSet`;
    await axios
      .get(requestUrl, {
        headers: {
          Authorization: store.getters.getLoginToken
        }
      })
      .then(dataSet => {
        store.commit("setDataSet", dataSet.data);
        // 주소에 데이터셋 정보다 있으면 그대로 사용하고, 없으면 데이터셋 첫번째를 api로 가져옴
        const UrlParametersObj = getParams();
        const dataSetNameKr = "dataSet" in UrlParametersObj ? UrlParametersObj["dataSet"] : store.getters.getDataSet[0].dataSetRepresentativeName;
        store.commit("setSelectedDataSet", dataSetNameKr);
        next();
      })
      .catch((error) => {
        alert("로그인을 다시 시도해주세요.");
        Vue.$cookies.remove(`loginToken`);
        router.push({ name: `Login` });
      });
  }
};
</script>
