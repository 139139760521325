<template>
  <div class="select">
    <select
      v-on:change="changedMenu(selectedDataSet)"
      v-model="selectedDataSet"
      style="color: black"
    >
      <option disabled selected>현재 데이터셋: {{ selectedDataSet }}</option>
      <option
        v-for="(dataSet, n) in this.$store.getters.getDataSet"
        :key="n"
        :selected="dataSet.dataSetRepresentativeName === selectedDataSet"
        >{{ dataSet.dataSetRepresentativeName }}</option
      >
    </select>
  </div>
</template>
<!--      v-on:change="changeMenu(defaultDataSet)"-->
<!--      v-model="defaultDataSet"-->
<script>
import axios from "axios";
import store from "@/store";
import { setColor } from "@/mixins/setColor";
import EventBus from "@/EventBus";
import { dataSetCodeConverter } from "../../../mixins/dataSetCodeConverter";

export default {
  mixins: [dataSetCodeConverter],
  data() {
    return {
      dataSetArray: [],
      dataSetsObj: [],
      selectedDataSet: this.$store.getters.getSelectedDataSet
    };
  },
  created() {
    this.dataSetArray = this.$store.getters.getDataSet.map(
      obj => obj.dataSetRepresentativeName
    );
    this.menuSelectManager(this.selectedDataSet);
  },

  methods: {
    menuSelectManager: function(selectedDataSet) {
      this.$store.commit("setSelectedDataSet", this.selectedDataSet);
    },
    updateCateGory: async function() {
      const DataSetCode = this.$store.getters.getDataSet.find(
        obj => obj.dataSetRepresentativeName === this.selectedDataSet
      ).dataSet;
      const requestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/getLandingPageCategoryInfo?dataSet=${DataSetCode}`
      const cateData = await axios.get(requestUrl, {headers: {
          Authorization: this.$store.getters.getLoginToken
        }});

      setColor(cateData);
      store.commit("setCategory", cateData.data);

      EventBus.$emit("reloadTreeMap", "전체");
    },
    changedMenu: async function(selectedDataSet) {
      this.menuSelectManager(selectedDataSet);
      const currentMenu = this.$route.matched[1].name;

      // 내용 탐색
      if (currentMenu === "ExploreContent") {
        if (this.$route.name === "ExploreContentFirstCategory") {
          await this.updateCateGory();
        } else {
          await this.updateCateGory();
          await this.$router.push({
            name: "ExploreContentFirstCategory",
            query: {
              dataSet: selectedDataSet,
              section: "전체"
            }
          });
        }
        EventBus.$emit("updateOntologyList");
        await this.$router.push({
          query: {
            dataSet: selectedDataSet,
            section: "전체"
          }
        });
        // 기간탐색
      } else if (true) {
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #ebebeb;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 256px;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: 0.25em;
}
select {
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #ffffff;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
</style>
