<template>
  <div
    style="position:fixed; width: 256px; height: 100%; float: left;word-break:break-all; background-color: #3c4b64"
  >
    <div
      style="height: 56px; background: #303c54; display: flex; align-items: center; }"
    >
      <div
        v-on:click="$router.push({ name: 'Index' })"
        style="cursor: pointer; text-align: center; font-size: 28px; margin: auto; font-family: 'Nanum Gothic', sans-serif; color: white"
      >
        NEXT <span id="logo">SEARCH</span>
      </div>
    </div>

    <div style="font-family: 'Rubik', sans-serif; color: white">
      <nav v-for="(menus, i) in sideMenu" :key="i" style="">
        <p
          style="font-size: 20px; margin-right:10px;   margin: 0;  padding: 20px 0px 0px 20px; padding-bottom: 15px; color: white"
        >
          {{ i }}
        </p>
        <ul v-for="(menu, n) in menus" :key="n">
          <li  @click="menu.goTo === '' ? alertWindow() : ''":style="{'background-color' : menu.enTitle===$route.matched[1].name ? 'darkgray':''}"
          >
            <router-link
              :to="{ name: menu.goTo }"
              style="display: flex; cursor: pointer; padding: 15px 0"
            >
              <div style="margin: 0 35px;">
                <v-icon large style="color: white; font-size: 25px">
                  {{ menu.icon }}
                </v-icon>
                <a
                  style="bottom: 0px; margin-left: 16px; color: white; text-decoration: none ; font-family: 'Nanum Gothic Coding', monospace; font-size: 18px; font-weight: 500;"
                  href="#"
                  class="menu-title"
                  >{{ menu.title }}</a
                >
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
      <div
        style="height: 56px; bottom: 0; width: 100%; position: absolute; width: 256px;"
      >
        <a href="" style=""
          ><i
            style="margin-left: 220px; color: white; font-size: 20px; margin-top: 18px;"
            class="fas fa-angle-left"
          ></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  components: {},
  data() {
    return {
      sideMenu: null,
      right: null,
      currentSelectedMenu: null
    };
  },
  created() {
    this.sideMenu = this.$store.getters.getSidebarMenu
  },
  methods: {
    alertWindow : function () {
      alert('준비 중인 메뉴입니다.')
    }
  }
}
</script>
<style scoped>
.background-black {
  background-color: darkgray;
}

li:hover {
  background-color: darkcyan;
  padding-left: 10px;
}
</style>
