<template>
  <div
    style="min-width: 750px; position: fixed; z-index: 200; width: calc(100% - 256px);
    left: 256px;"
  >
    <div
      style="background-color: #303d54; height: 57px; border-bottom: 1px solid rgb(216, 219, 224); min-width: 750px;"
    >
      <div v-if="this.$route.name !== 'Login'">
        <nav style="margin-left:40px; float: right">
          <ul class="menu">
            <li>
              <span style="color: #8b8b94;">Hello.</span>
              <span style="font-weight: 500"
                >[ {{ this.$cookies.get("loginUserId") }} ]</span
              >
            </li>
          </ul>
        </nav>

        <nav style="float: right; margin-top: 3px">
          <Combobox data-app></Combobox>
        </nav>

        <nav style="">
          <v-btn
            @click="
              $cookies.remove('loginToken');
              $router.push({ name: 'Login' });
            "
            style="font-size: 10px; font-weight: 300; height: 46px; margin: 4px;float: right;margin-right: 6px; "
            >logout
            <v-icon style="cursor: pointer; font-size: 24px"
              >mdi-logout
            </v-icon></v-btn
          >
        </nav>
        <!--        <nav style="float: right; margin-top: 3px">-->
        <!--          <router-link :to="{name:'ExplorePeriodLineChart'}"><v-btn>Line Chart</v-btn></router-link>-->
        <!--          <router-link :to="{name:'ExplorePeriodFrequencyMasonry'}"><v-btn>Masonry</v-btn></router-link>-->
        <!--          <router-link :to="{name:'ExplorePeriodCategoryFrequencyBarChartList'}"><v-btn>BarChartList</v-btn></router-link>-->
        <!--          <router-link :to="{name:'ExplorePeriodDashboard'}"><v-btn>Dashboard</v-btn></router-link>-->
        <!--          <router-link :to="{name:'ExplorePeriodNewDashboard'}"><v-btn>newDashboard</v-btn></router-link>-->
        <!--        </nav>-->
      </div>
    </div>
  </div>
</template>

<script>
import Combobox from "./Combobox";
export default {
  name: "Header",
  components: {
    Combobox
  },
  data() {
    return {
      pageName: {},
      currentPageName: null
    };
  },
  mounted() {
    const sideMenu = this.$store.getters.getSidebarMenu;
    for (const [section, menuArray] of Object.entries(sideMenu)) {
      for (const [index, menuInfo] of Object.entries(menuArray)) {
        this.pageName[menuInfo.enTitle] = menuInfo.title;
      }
    }
    this.currentPageName = this.pageName[this.$route.matched[1].name];
  },
  updated() {
    this.currentPageName = this.pageName[this.$route.matched[1].name];
  }
};
</script>

<style scoped>
.menu li {
  display: inline-block;
  margin: 17px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  margin-right: 35px;
}
</style>
