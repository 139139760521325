export function getParams() {
    var options = window.location.search.slice(1)
        .split('&')
        .reduce(function _reduce (/*Object*/ a: any, /*String*/ b: any) {
            b = b.split('=');
            a[b[0]] = decodeURIComponent(b[1]);
            return a;
        }, {});
    return options
}
